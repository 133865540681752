// extracted by mini-css-extract-plugin
export var titles = "hire_us-module--titles--IJX7j";
export var form_container = "hire_us-module--form_container--3E7U7";
export var subtitle = "hire_us-module--subtitle--28dyY";
export var title = "hire_us-module--title--2dSDy";
export var form = "hire_us-module--form--lncYD";
export var label = "hire_us-module--label--1y2W8";
export var input = "hire_us-module--input--bg98X";
export var textarea = "hire_us-module--textarea--S29pS";
export var radio = "hire_us-module--radio--3QLrr";
export var radio_buttons = "hire_us-module--radio_buttons--3aZtt";
export var inputs_container = "hire_us-module--inputs_container--1mK_V";
export var send = "hire_us-module--send--39nVx";
export var image_container = "hire_us-module--image_container--QgkM0";
export var image_team = "hire_us-module--image_team--2QXwn";
export var erros_container = "hire_us-module--erros_container--3WIiX";
export var success_container = "hire_us-module--success_container--1IrYA";
export var sending = "hire_us-module--sending--4bCqM";