import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import * as styles from "./hire_us.module.css"
import { http } from "../../services/lead"
import { i18n } from "../../translate/i18n"
import { validateRequiredFields } from "../../utils/validateRequiredFields"

import Layout from "../../components/layout"
import Grid from "../../components/grid"

function HireUs({ location }) {
  const siteTitle = "Contrate-nos"
  const [formAttributes, setFormAttributes] = React.useState({})
  const [sending, setSending] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState("")
  const [success, setSuccess] = React.useState(false)

  async function handleFormSubmit(event) {
    event.preventDefault()

    setErrorMessage("")
    setSending(true)

    const requiredFields = [
      { name: "company_name", text: i18n.t("errors.errorNameProject") },
      { name: "name", text: i18n.t("errors.errorName") },
      { name: "email", text: i18n.t("errors.errorEmail") },
    ]
    const errors = validateRequiredFields({ requiredFields, formAttributes })

    if (errors.length !== 0) {
      setErrorMessage(errors.join(", "))
      setSending(false)

      return
    }

    const body = { ...formAttributes }

    try {
      const response = await http.post("/lead", body)

      if (response.data?.errors?.length > 0) {
        const errors = response.data.errors.map(error => error.title)

        setErrorMessage(errors.join(", "))
        setSending(false)
        return
      }

      setSuccess(true)
    } catch (e) {
      setErrorMessage(i18n.t("errors.errorUnexpected"))
    }

    setSending(false)
  }

  function handleChange(event) {
    setFormAttributes(
      Object.assign(formAttributes, { [event.target.name]: event.target.value })
    )
  }

  return (
    <Layout location={location} headerHome={false} title={siteTitle}>
      <Grid>
        <section className={styles.titles}>
          <div className={styles.subtitle}>LET'S TALK</div>
          <h1 className={styles.title}>
            {i18n.t("startingProject.strSuccess")}
          </h1>
        </section>
        <section className={styles.form_container}>
          <form className={styles.form} onSubmit={handleFormSubmit}>
            <div className={styles.inputs_container}>
              <label
                className={styles.label}
                for="company_name"
                htmlFor="company_name"
              >
                {" "}
                {i18n.t("startingProject.strCompany")}
              </label>
              <input
                onChange={handleChange}
                className={`${styles.input}`}
                type="text"
                name="company_name"
                id="company_name"
              ></input>
            </div>

            <div className={styles.inputs_container}>
              <label className={styles.label} for="name" htmlFor="name">
                {i18n.t("startingProject.strName")}
              </label>
              <input
                onChange={handleChange}
                className={`${styles.input}`}
                type="text"
                name="name"
                id="name"
              ></input>
            </div>

            <div className={styles.inputs_container}>
              <label className={styles.label} for="email" htmlFor="email">
                {i18n.t("startingProject.strEmail")}
              </label>
              <input
                onChange={handleChange}
                className={`${styles.input}`}
                type="email"
                name="email"
                id="email"
              ></input>
            </div>

            <div className={styles.inputs_container}>
              <label className={styles.label} for="phone" htmlFor="phone">
                {i18n.t("startingProject.strPhone")}
              </label>
              <input
                onChange={handleChange}
                className={`${styles.input}`}
                type="phone"
                name="phone"
                id="phone"
              ></input>
            </div>

            <div className={styles.inputs_container}>
              <label
                className={styles.label}
                for="project_type"
                htmlFor="project_type"
              >
                {i18n.t("startingProject.strCanDo")}
              </label>
              {/* <input type="phone" name="phone" id="phone"></input> */}

              <span className={styles.radio}>
                <input
                  onChange={handleChange}
                  className={styles.radio_buttons}
                  id="mvp"
                  type="radio"
                  name="project_type"
                  value="MVP"
                ></input>
                <label for="mvp" htmlFor="mvp">
                  {i18n.t("startingProject.strProduct")}{" "}
                  <span role="img" aria-label="rocket">
                    🚀
                  </span>
                </label>
              </span>

              <span className={styles.radio}>
                <input
                  onChange={handleChange}
                  className={styles.radio_buttons}
                  id="started"
                  type="radio"
                  name="project_type"
                  value="Projeto iniciado"
                ></input>
                <label for="started" htmlFor="started">
                  {i18n.t("startingProject.strGreater")}{" "}
                  <span role="img" aria-label="Flexed Biceps">
                    💪
                  </span>
                </label>
              </span>

              <span className={styles.radio}>
                <input
                  onChange={handleChange}
                  className={styles.radio_buttons}
                  id="maintance"
                  type="radio"
                  name="project_type"
                  value="Manutenção"
                ></input>
                <label for="maintance" htmlFor="maintance">
                  {i18n.t("startingProject.strServers")}
                  <span role="img" aria-label="Gear">
                    ⚙️
                  </span>
                </label>
              </span>

              <span className={styles.radio}>
                <input
                  onChange={handleChange}
                  className={styles.radio_buttons}
                  id="other_project"
                  type="radio"
                  name="project_type"
                  value="Other"
                ></input>
                <label for="other_project" htmlFor="other_project">
                  {i18n.t("startingProject.strOther")}
                </label>
              </span>
            </div>

            <div className={styles.inputs_container}>
              <label
                className={styles.label}
                for="description"
                htmlFor="description"
              >
                {i18n.t("startingProject.strTellUs")}
              </label>
              <textarea
                onChange={handleChange}
                className={`${styles.input} ${styles.textarea}`}
                name="description"
                id="description"
              ></textarea>
            </div>

            <div className={styles.inputs_container}>
              <label className={styles.label} for="budget" htmlFor="budget">
                {i18n.t("startingProject.strBudget")}
              </label>
              {/* <input type="phone" name="phone" id="phone"></input> */}

              <span className={styles.radio}>
                <input
                  onChange={handleChange}
                  className={styles.radio_buttons}
                  type="radio"
                  id="25b"
                  name="budget"
                  value="Menos que R$ 25.000,00"
                ></input>
                <label for="25b" htmlFor="25b">
                  {i18n.t("startingProject.strLessThan")}{" "}
                </label>
              </span>

              <span className={styles.radio}>
                <input
                  onChange={handleChange}
                  className={styles.radio_buttons}
                  type="radio"
                  id="50b"
                  name="budget"
                  value="R$ 25.000,00 - R$ 50.000,00"
                ></input>
                <label for="50b" htmlFor="50b">
                  R$ 25.000,00 - R$ 50.000,00
                </label>
              </span>

              <span className={styles.radio}>
                <input
                  onChange={handleChange}
                  className={styles.radio_buttons}
                  type="radio"
                  id="100b"
                  name="budget"
                  value="R$ 50.000,00 - R$ 100.000,00"
                ></input>
                <label for="100b" htmlFor="100b">
                  R$ 50.000,00 - R$ 100.000,00
                </label>
              </span>

              <span className={styles.radio}>
                <input
                  onChange={handleChange}
                  className={styles.radio_buttons}
                  type="radio"
                  id="100more"
                  name="budget"
                  value="R$ 100.000,00 ou mais"
                ></input>
                <label for="100more" htmlFor="100more">
                  {i18n.t("startingProject.strOrMore")}
                </label>
              </span>

              <span className={styles.radio}>
                <input
                  onChange={handleChange}
                  className={styles.radio_buttons}
                  type="radio"
                  id="indefined_budget"
                  name="budget"
                  value="Não definido"
                ></input>
                <label for="indefined_budget" htmlFor="indefined_budget">
                  {i18n.t("startingProject.strNotDefined")}
                </label>
              </span>
            </div>

            <div className={styles.inputs_container}>
              <label className={styles.label} for="referrer" htmlFor="referrer">
                {i18n.t("startingProject.strMeetUs")}
              </label>
              <input
                onChange={handleChange}
                className={`${styles.input}`}
                type="text"
                name="referrer"
                id="referrer"
              ></input>
            </div>

            <div
              className={styles.erros_container}
              style={{ display: errorMessage.length === 0 ? "none" : "block" }}
            >
              <span>{errorMessage}</span>
            </div>

            <div
              className={styles.success_container}
              style={{ display: success ? "block" : "none" }}
            >
              <span>
                {i18n.t("startingProject.strRegister")}
                <br />
                <br />
                {i18n.t("startingProject.strThanks")}
              </span>
            </div>

            <div>
              <button
                className={`${styles.send} ${sending ? styles.sending : ""}`}
              >
                {i18n.t("startingProject.strSend")}
              </button>
            </div>
          </form>
        </section>

        <section className={styles.image_container}>
          <StaticImage
            src="../../images/hire-us/hire-us-team.png"
            alt="Time da nullbug"
            className={styles.image_team}
          />
        </section>
      </Grid>
    </Layout>
  )
}

export default HireUs
